"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionService = void 0;
const jschardet = require("jschardet");
const file_saver_1 = require("file-saver");
class ActionService {
    constructor($filter) {
        this.$filter = $filter;
    }
    uploadFactory(accept, maxSize = 1, multiple = false) {
        const self = this;
        let el = document.createElement("INPUT");
        el.type = "file";
        el.accept = accept;
        let fileType = accept.split('/', 1)[0];
        return new Promise((resolve, reject) => {
            let filesCount = 0;
            let loadList = [];
            el.addEventListener('change', (event) => {
                if (el.files.length) {
                    while (filesCount < el.files.length) {
                        const file = el.files.item(filesCount);
                        if (!new RegExp(`^${fileType}\/.*`).test(file.type)) {
                            reject(self.$filter('translate')('__unknown_file_type__'));
                            break;
                        }
                        else {
                            if (file.size > (maxSize * 1024 * 1024)) {
                                reject(self.$filter('translate')('__max_file_size__', { maxFileSize: maxSize }));
                                break;
                            }
                            else {
                                let r = new FileReader();
                                let loadPromise;
                                if (fileType == 'image') {
                                    loadPromise = new Promise((resolve, reject) => {
                                        r.onload = (f) => {
                                            resolve(f.target.result);
                                            return f;
                                        };
                                        r.onerror = reject;
                                        r.onabort = reject;
                                    });
                                    r.readAsDataURL(file);
                                }
                                else {
                                    loadPromise = new Promise((resolve, reject) => {
                                        r.onload = (f) => {
                                            const encoding = jschardet.detect(f.target.result).encoding;
                                            file.arrayBuffer().then((value) => {
                                                resolve(new TextDecoder(encoding || 'UTF-8').decode(new Uint8Array(value)));
                                            }, reject);
                                        };
                                        r.onerror = reject;
                                        r.onabort = reject;
                                    });
                                    r.readAsBinaryString(file);
                                }
                                loadList.push(loadPromise);
                            }
                        }
                        if (multiple)
                            filesCount++;
                        else
                            break;
                    }
                    Promise.all(loadList).then((values) => {
                        resolve(values.join('\n'));
                    }, (err) => {
                        reject(err);
                    });
                }
                else {
                    reject(null);
                }
            }, { once: true });
            el.click();
        });
    }
    downloadFactory(name, value) {
        var _a, _b;
        if (value instanceof Blob) {
            const matches = value.type.match(/\/(?<ext>.+)$/);
            if ((_a = matches.groups) === null || _a === void 0 ? void 0 : _a.ext) {
                const extMatch = (_b = matches.groups) === null || _b === void 0 ? void 0 : _b.ext;
                let exts = {};
                exts[extMatch] = extMatch;
                const ext = Object.assign(Object.assign({}, exts), { 'svg+xml': 'svg', 'jpeg': 'jpg', 'vnd.microsoft.icon': 'ico' })[extMatch];
                (0, file_saver_1.saveAs)(value, `${name}.${ext}`);
            }
            else {
                alert(this.$filter('translate')('__unknown_image_type__'));
            }
        }
        else {
            // @ts-ignore
            (0, file_saver_1.saveAs)(new Blob([value], { type: "text/plain;charset=utf8", encoding: "UTF-8" }), `${name}.txt`);
        }
        // saveAs(new Blob([value], {type: "text/plain;charset=cp1251", encoding: "cp1251"}), `${name}.txt`)
    }
    copyFactory(el, value) {
        console.log(el, value, el instanceof HTMLInputElement);
        if (navigator.clipboard && window.isSecureContext && (value != undefined)) {
            // @ts-ignore
            navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                if (result.state == "granted" || result.state == "prompt") {
                    navigator.clipboard.writeText(value);
                }
            });
        }
        else {
            const currentActive = document.activeElement;
            let type;
            if (el instanceof HTMLTextAreaElement) {
                el.focus();
                el.select();
            }
            if (el instanceof HTMLInputElement) {
                type = el.type;
                el.type = 'text';
                el.focus();
                el.select();
            }
            else if (el) {
                const range = document.createRange();
                range.selectNode(el);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand('copy');
            if (currentActive) {
                currentActive.focus();
            }
            window.getSelection().removeAllRanges();
            if (type != undefined)
                el.type = type;
        }
    }
}
exports.ActionService = ActionService;
ActionService.$inject = ['$filter'];
